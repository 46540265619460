import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bgShape2 from "@images/banner/banner-shape-1-2.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import bannerShape1 from "@images/shapes/banner-1-1.png";
import bannerShape2 from "@images/banner/banner-shape-1-4.png";
import logo from "@images/slack.svg";
import image from "@images/logo-1-1.svg";

const Banner = () => {
  return (
    <section className="banner-one" id="home">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <img src={bgShape2} alt="Banner-Shape-2" className="banner-shape-2" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={6} lg={6}>
            <div className="banner-one__content">
              <h3>
                Unleash <br/>the power of GPT <br/> with Wicebot. <br />
                <br />
              </h3>
              <p>
              Wicebot is an AI assistant powered by OpenAI's GPT-3 model, designed to help with a variety of tasks on Slack including marketing, coding, email writing, idea grooming, fact-checking, and grammar correction. Users can simply mention Wicebot in a Slack channel and ask for assistance, and Wicebot will generate effective and creative solutions based on the user's needs and goals. It is a versatile tool that can save time and increase productivity for businesses and individuals.
                <br />
              </p>
            {/*  <a href="https://slack.com/oauth/v2/authorize?client_id=4658630895395.4682457613072&scope=app_mentions:read,im:history,chat:write,commands&user_scope=">
              <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>*/}
            </div>
          </Col>
          <Col xl={6} lg={6}>
            <div className="banner-img wow fadeInUp" data-wow-duration="1500ms">
              <img src={bannerShape1} className="banner-image__curvs" alt="" />
              <div
                className="banner-bg"
                style={{ backgroundImage: `url(${bannerShape2})` }}
              ></div>
              <img src={image} alt="Banner-img" className="banner-img" />
              <div className="banner-icon-1">
                <i className="piloz-lamp"></i>
              </div>
              <div className="banner-icon-2">
                <i className="piloz-linked"></i>
              </div>
              <div className="banner-icon-3">
                <i className="piloz-human-resources"></i>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
