import React, { useState } from "react";

const FaqCard = ({ status, title, text, gifUrl }) => {
  const [active, setActive] = useState(status);
  return (
    <div className={`accrodion ${active === true ? "active" : " "}`}>
      <div className="accrodion-inner">
        <div className="accrodion-title" onClick = {() => setActive(!active)}>
          <h4>{title}</h4>
        </div>
        {active == true ? (
          <div className="accrodion-content animated fadeInUp">
            <div className="inner">
              <p>{text}</p>
              {gifUrl && <img src={gifUrl} alt="My GIF" width="100%"></img>}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FaqCard;
